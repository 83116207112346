import React from "react"
import { MDXProvider } from "@mdx-js/react"
import { Link, graphql } from "gatsby"
import MDXRenderer from "gatsby-plugin-mdx/mdx-renderer"
import moment from "moment"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit } from "@fortawesome/free-solid-svg-icons"

import GlobalLayout from "./global-layout"
import SEO from "../components/seo"
import CodeBlock from "../components/codeblock"
import * as styles from "./post-layout.module.css"

const components = {
    pre: props => <div {...props} />,
    code: CodeBlock,
    Link,
}

export default function PostLayout ({ data }) {
  return (
    <GlobalLayout>
      <SEO
        title={data.mdx.frontmatter.title}
        description={data.mdx.excerpt}
        keywords={data.mdx.frontmatter.keywords}
      />
      <article>
        <header className={styles.postHead}>
          <h1>{data.mdx.frontmatter.title}</h1>
          <span className={styles.postTimes}>
            <span className={styles.postTimeSet}>
              <FontAwesomeIcon icon={faEdit} size="sm"/>
              <time>{moment(data.mdx.frontmatter.date).utc().format(`YYYY-MM-DD`)}</time>
            </span>
          </span>
        </header>
        <section className={styles.postContent}>
          <MDXProvider
            components={components}
          >
            <MDXRenderer>{data.mdx.body}</MDXRenderer>
          </MDXProvider>
        </section>
      </article>
    </GlobalLayout>
  );
}

export const query = graphql`
  query($slug: String!) {
    mdx( fields: { slug: { eq: $slug } }) {
      body
      excerpt(truncate: true)
      frontmatter {
        title
        date
        keywords
      }
    }
  }
`
