import React from 'react'
import Highlight, {defaultProps} from 'prism-react-renderer'
import theme from "prism-react-renderer/themes/oceanicNext";
import * as styles from "./codeblock.module.css"


export default function CodeBlock({children, className}) {
  let [language, title] = (className || '').split(':');
  language = language.replace(/language-/, '')
  const CodeTitle = () => {
    if (title) {
      return (
        <div className={styles.codeTitle}>
          <span>{title}</span>
        </div>
      )
    }
    return (
      <span></span>
    )
  }

  return (
    <Highlight {...defaultProps} theme={theme} code={children} language={language}>
      {({ className, style, tokens, getLineProps, getTokenProps }) => {
        tokens.pop()
        return (
          <div className={styles.codeBlockRoot}>
            <CodeTitle />
            <pre className={`${styles.codePre} ${className}`} style={style}>
              {tokens.map((line, i) => {
                const {
                    style: s,
                    className: c,
                } = getLineProps({ line, key: i })
                return (
                  <div key={i} style={s} className={`${styles.codeLine} ${c}`}>
                    <span className={styles.codeLineNumber}>{i + 1}</span>
                    <span className={styles.codeLineContent}>
                      {line.map((token, key) => (
                        <span key={key} {...getTokenProps({ token, key })} />
                      ))}
                    </span>
                  </div>
                )
              })}
            </pre>
          </div>
        )
      }}
    </Highlight>
  )
}